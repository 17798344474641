var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading && !_vm.isAvailable,
              expression: "!isLoading && !isAvailable"
            }
          ],
          attrs: { column: "", wrap: "" }
        },
        [
          _c("p", { staticClass: "mt-2" }, [
            _vm._v(_vm._s(_vm.$t("description.userNotFound")))
          ]),
          _c(
            "v-btn",
            {
              staticClass: "font-weight-black float-right mr-4",
              staticStyle: { "font-size": "20px" },
              attrs: {
                width: "70vw",
                "max-width": "350px",
                color: "next",
                dark: ""
              },
              on: {
                click: function($event) {
                  return _vm.onClickBackHomeButton()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("button.backHome")))]
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading && _vm.isAvailable,
              expression: "!isLoading && isAvailable"
            }
          ],
          attrs: { column: "", wrap: "" }
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "p",
                  {
                    staticClass: "font-weight-black pl-3",
                    staticStyle: { "font-size": "32px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("title.agency.userDetail")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c("v-row", [
            _c(
              "p",
              {
                staticClass: "pl-5",
                staticStyle: { "word-wrap": "break-all", "max-width": "90vw" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("description.userDetailTop.explanation1")) +
                    "\n      "
                )
              ]
            )
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "pb-0" }, [
                        _c(
                          "p",
                          {
                            staticClass: "pl-5 mt-3 mb-0 font-weight-black",
                            staticStyle: { "font-size": "20px" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("label.userInfo")) +
                                "\n            "
                            )
                          ]
                        )
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "pb-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight-black float-right",
                              staticStyle: { "font-size": "20px" },
                              attrs: {
                                slot: "activator",
                                align: "center",
                                width: "70vw",
                                "max-width": "250px",
                                color: "next",
                                dark: _vm.isUserEditButtonEnabled,
                                disabled: !_vm.isUserEditButtonEnabled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onClickEditUserButton()
                                }
                              },
                              slot: "activator"
                            },
                            [_vm._v(_vm._s(_vm.$t("button.userEdit")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-simple-table",
                    {
                      staticClass:
                        "elevation-1 v_base_table_fill_width v_base_table_with_border",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _c(
                        "tbody",
                        _vm._l(_vm.userRecords, function(userRecord) {
                          return _c(
                            "tr",
                            { key: userRecord.key },
                            [
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "text-left .v_base_table_with_border base_td_category_text"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "header.userListTable." +
                                            userRecord.key
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              userRecord.key === "userName"
                                ? [
                                    _c(
                                      "td",
                                      { class: _vm.checkLockoutStyle() },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(userRecord.value) +
                                            "\n                  "
                                        ),
                                        _vm.isLocked
                                          ? [
                                              _vm._v(
                                                "\n                    (" +
                                                  _vm._s(
                                                    _vm.$t("label.accountLock")
                                                  ) +
                                                  ")\n                  "
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                : userRecord.key === "comment"
                                ? [
                                    _c(
                                      "td",
                                      { staticClass: "py-4" },
                                      _vm._l(userRecord.value, function(
                                        value,
                                        index
                                      ) {
                                        return _c(
                                          "p",
                                          {
                                            key: index,
                                            staticClass: "ma-0 pa-0",
                                            attrs: { align: "left" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(value) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                : [_c("td", [_vm._v(_vm._s(userRecord.value))])]
                            ],
                            2
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pt-10" },
            [
              _c(
                "v-col",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        align: "center",
                        width: "25vw",
                        "max-width": "300px",
                        color: "next",
                        dark: _vm.isUserPasswordResetButtonEnabled,
                        disabled: !_vm.isUserPasswordResetButtonEnabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.onClickResetPasswordButton()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.passwordReset")))]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        align: "center",
                        width: "25vw",
                        "max-width": "300px",
                        color: "next",
                        dark: _vm.isUserDeleteButtonEnabled,
                        disabled: !_vm.isUserDeleteButtonEnabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.onClickUserDeleteButton()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.userDelete")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pt-5" },
            [
              _c(
                "v-col",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        align: "center",
                        width: "25vw",
                        "max-width": "300px",
                        color: "next",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.onClickPrevUserButton()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.prevUser")))]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        align: "center",
                        width: "25vw",
                        "max-width": "300px",
                        color: "next",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.onClickNextUserButton()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.nextUser")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showEditUserDialog,
              title: _vm.$t("title.agency.userEdit"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.cancelEditUser,
              onClickPositiveButton: _vm.updateUser,
              targets: _vm.editUserTargets
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showPasswordResendConfirmDialog,
              title: _vm.$t("title.agency.passwordResendConfirm"),
              text: null,
              subText: null,
              multiLineText: null,
              confirmText: _vm.$t("description.confirmResendPassword"),
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.yes"),
              onClickNegativeButton: _vm.cancelResendPassword,
              onClickPositiveButton: _vm.resendPassword
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showPasswordResetConfirmDialog,
              title: _vm.$t("title.agency.passwordResetConfirm"),
              text: null,
              subText: null,
              multiLineText: null,
              confirmText: _vm.$t("description.confirmResetPassword"),
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.yes"),
              onClickNegativeButton: _vm.cancelResetPassword,
              onClickPositiveButton: _vm.resetPassword
            }
          }),
          _c("DeleteDialog", {
            ref: "userDeleteDialog",
            attrs: { title: _vm.$t("title.agency.userDelete") },
            on: { onSuccess: _vm.onSuccessDeleteUser }
          }),
          _c("CompletedDialog", { ref: "completedDialog" }),
          _c("ErrorDialog", { ref: "errorDialog" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }