<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout v-show="!isLoading && !isAvailable" column wrap>
      <p class="mt-2">{{ $t('description.userNotFound') }}</p>
      <v-btn
        class="font-weight-black float-right mr-4"
        width="70vw"
        max-width="350px"
        color="next"
        style="font-size:20px"
        dark
        @click="onClickBackHomeButton()"
        >{{ $t('button.backHome') }}</v-btn
      >
    </v-layout>

    <v-layout v-show="!isLoading && isAvailable" column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('title.agency.userDetail') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <p class="pl-5" style="word-wrap: break-all; max-width:90vw">
          {{ $t('description.userDetailTop.explanation1') }}
        </p>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col class="pb-0">
              <p
                class="pl-5 mt-3 mb-0 font-weight-black"
                style="font-size:20px;"
              >
                {{ $t('label.userInfo') }}
              </p>
            </v-col>
            <v-col class="pb-0">
              <v-btn
                class="font-weight-black float-right"
                align="center"
                width="70vw"
                max-width="250px"
                color="next"
                style="font-size:20px"
                :dark="isUserEditButtonEnabled"
                :disabled="!isUserEditButtonEnabled"
                slot="activator"
                @click="onClickEditUserButton()"
                >{{ $t('button.userEdit') }}</v-btn
              >
            </v-col>
          </v-row>

          <v-simple-table
            class="elevation-1 v_base_table_fill_width v_base_table_with_border"
            style="width: 100%;"
          >
            <tbody>
              <tr v-for="userRecord in userRecords" :key="userRecord.key">
                <td
                  class="text-left .v_base_table_with_border base_td_category_text"
                >
                  {{ $t(`header.userListTable.${userRecord.key}`) }}
                </td>
                <template v-if="userRecord.key === 'userName'">
                  <td :class="checkLockoutStyle()">
                    {{ userRecord.value }}
                    <template v-if="isLocked">
                      ({{ $t('label.accountLock') }})
                    </template>
                  </td>
                </template>
                <template v-else-if="userRecord.key === 'comment'">
                  <td class="py-4">
                    <p
                      v-for="(value, index) in userRecord.value"
                      :key="index"
                      class="ma-0 pa-0"
                      align="left"
                    >
                      {{ value }}
                    </p>
                  </td>
                </template>
                <template v-else>
                  <td>{{ userRecord.value }}</td>
                </template>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row class="pt-10">
        <v-col style="text-align: center">
          <v-btn
            class="font-weight-black"
            align="center"
            width="25vw"
            max-width="300px"
            color="next"
            style="font-size:20px"
            :dark="isUserPasswordResetButtonEnabled"
            :disabled="!isUserPasswordResetButtonEnabled"
            @click="onClickResetPasswordButton()"
            >{{ $t('button.passwordReset') }}</v-btn
          >
        </v-col>
        <v-col style="text-align: center">
          <v-btn
            class="font-weight-black"
            align="center"
            width="25vw"
            max-width="300px"
            color="next"
            style="font-size:20px"
            :dark="isUserDeleteButtonEnabled"
            :disabled="!isUserDeleteButtonEnabled"
            @click="onClickUserDeleteButton()"
            >{{ $t('button.userDelete') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="pt-5">
        <v-col style="text-align: center">
          <v-btn
            class="font-weight-black"
            align="center"
            width="25vw"
            max-width="300px"
            color="next"
            style="font-size:20px"
            dark
            @click="onClickPrevUserButton()"
            >{{ $t('button.prevUser') }}</v-btn
          >
        </v-col>
        <v-col style="text-align: center">
          <v-btn
            class="font-weight-black"
            align="center"
            width="25vw"
            max-width="300px"
            color="next"
            style="font-size:20px"
            dark
            @click="onClickNextUserButton()"
            >{{ $t('button.nextUser') }}</v-btn
          >
        </v-col>
      </v-row>
      <!-- ユーザー編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditUserDialog"
        :title="$t('title.agency.userEdit')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEditUser"
        :onClickPositiveButton="updateUser"
        :targets="editUserTargets"
      />
      <!-- 仮パスワード再発行確認ダイアログ -->
      <SimpleDialog
        :showDialog="showPasswordResendConfirmDialog"
        :title="$t('title.agency.passwordResendConfirm')"
        :text="null"
        :subText="null"
        :multiLineText="null"
        :confirmText="$t('description.confirmResendPassword')"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.yes')"
        :onClickNegativeButton="cancelResendPassword"
        :onClickPositiveButton="resendPassword"
      />
      <!-- パスワード初期化ダイアログ -->
      <SimpleDialog
        :showDialog="showPasswordResetConfirmDialog"
        :title="$t('title.agency.passwordResetConfirm')"
        :text="null"
        :subText="null"
        :multiLineText="null"
        :confirmText="$t('description.confirmResetPassword')"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.yes')"
        :onClickNegativeButton="cancelResetPassword"
        :onClickPositiveButton="resetPassword"
      />
      <!-- ユーザー削除ダイアログ -->
      <DeleteDialog
        :title="$t('title.agency.userDelete')"
        @onSuccess="onSuccessDeleteUser"
        ref="userDeleteDialog"
      />
      <CompletedDialog ref="completedDialog"></CompletedDialog>
      <ErrorDialog ref="errorDialog"></ErrorDialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import {
  convertLastUpdatedDate,
  convertLastLoginDate,
  convertFirstRegisteredDate,
  convertIsDeleted,
} from '@/lib/agency/util';
import { ErrorCode, MaxCount, MaxLength, TextStyle } from '@/lib/const';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import DeleteDialog from '@/components/organisms/agency/DeleteDialog';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import { Role } from '@/lib/role';
import { splitMessageLine } from '@/lib/commonUtil';
import {
  getUser,
  getUserId,
  putUser,
  deleteUser,
  getLoginUser,
  putUserPasswordResend,
  putUserPasswordReset,
} from '@/apis/agency/users';

export default {
  name: 'UserDetail',
  components: {
    ErrorDialog,
    FormsDialog,
    SimpleDialog,
    DeleteDialog,
    CompletedDialog,
  },
  data: vm => ({
    // ユーザーデータ
    user: {},

    // ユーザー情報
    userRecords: [],

    // 権限の選択肢
    roleList: [],

    // 事業所の選択肢
    officeIdList: [],

    // ユーザー編集データ (デフォルト)
    editUserDefaultTargets: [
      {
        value: null,
        targetName: 'userName',
        type: 'text',
        label: vm.$t('header.userMasterTable.userName'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        key: 'userName',
      },
      {
        value: null,
        targetName: 'userMail',
        type: 'text',
        label: vm.$t('header.userMasterTable.userMail'),
        text: null,
        rule: `required|noSpace|pattern:mailAddress|max:${MaxLength.MailAddress}`,
        key: 'userMail',
      },
      {
        value: null,
        targetName: 'role',
        type: 'select',
        label: vm.$t('header.userMasterTable.role'),
        text: null,
        rule: 'selectRequired',
        key: 'role',
        listName: 'roleList',
        function: vm.onChangeRole,
      },
      {
        value: null,
        targetName: 'recruiterId',
        type: 'text',
        label: vm.$t('header.userMasterTable.recruiterId'),
        text: null,
        rule: `pattern:recruiterId|max:${MaxLength.RecruiterId}`,
        key: 'recruiterId',
      },
      {
        value: null,
        targetName: 'salesCode',
        type: 'text',
        label: vm.$t('header.userMasterTable.salesCode'),
        text: null,
        rule: `required|noSpace|pattern:salesCode`,
        pattern: 'sales',
        key: 'salesCode',
      },
      {
        value: null,
        targetName: 'agencyCode',
        type: 'text',
        label: vm.$t('header.userMasterTable.agencyCode'),
        text: null,
        rule: `required|noSpace|pattern:agencyCode`,
        pattern: 'agency',
        key: 'agencyCode',
      },
      {
        value: null,
        targetName: 'comment',
        type: 'textarea',
        label: vm.$t('header.userMasterTable.comment'),
        text: null,
        rule: `max:${MaxLength.UserComment}`,
        key: 'comment',
        max: MaxLength.UserComment,
      },
      {
        value: null,
        targetName: 'chargePersonName',
        type: 'text',
        label: vm.$t('header.userMasterTable.chargePersonName'),
        subheader: vm.$t('label.chargePersonInfo'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        pattern: 'agency',
        key: 'chargePersonName',
      },
      {
        value: null,
        targetName: 'chargePersonPhoneNumber',
        type: 'phone',
        label: vm.$t('header.userMasterTable.chargePersonPhoneNumber'),
        text: null,
        rule: 'required|noSpace|telNumberLength',
        pattern: 'agency',
        key: 'chargePersonPhoneNumber',
      },
    ],

    // ユーザー編集データ
    editUserTargets: [],

    // 代理店ユーザーであるかどうか
    isAgencyUser: [],

    // ユーザー編集ダイアログが表示されているかどうか
    showEditUserDialog: false,

    // 仮パスワード再発行確認ダイアログが表示されているかどうか
    showPasswordResendConfirmDialog: false,

    // パスワード初期化確認ダイアログが表示されているかどうか
    showPasswordResetConfirmDialog: false,

    // ユーザー編集ボタンが有効であるかどうか
    isUserEditButtonEnabled: false,

    // ユーザー削除ボタンが有効であるかどうか
    isUserDeleteButtonEnabled: false,

    // パスワード初期化ボタンが有効であるかどうか
    isUserPasswordResetButtonEnabled: false,

    // ローディング中であるかどうか
    isLoading: true,

    // 有効であるかどうか
    isAvailable: true,

    // ロック中かどうか
    isLocked: false,
  }),
  async mounted() {
    // ユーザーの取得
    await this.fetchUsers();

    this.setRoleChoices();
    this.onChangeRole({
      value: this.user.role,
    });

    // ロックアウト状態をセットする
    this.$set(this, 'isLocked', this.user.errorCount >= MaxCount.loginError);
  },
  methods: {
    ...mapGetters('user', ['userDetail']),
    ...mapActions('user', ['setUserDetail']),

    // 権限の選択肢の取得
    setRoleChoices() {
      // 操作可能な権限
      const roleList = Role.getChoices(this.userDetail().role, this.user.role);
      // 権限の選択肢表示の更新
      this.$set(this, 'roleList', roleList);
    },

    // ユーザーの取得
    async fetchUsers() {
      const user = await getUser(this.$route.params['user_id']).catch(() => {
        this.$set(this, 'isAvailable', false);
      });
      this.$set(this, 'isLoading', false);

      // ユーザーが取得できなかった場合、以降の処理を中止する
      if (!user) return;

      // ユーザー編集ボタンの有効状態をセットする
      this.$set(
        this,
        'isUserEditButtonEnabled',
        Role.isButtonEnabled(this.userDetail(), 'userEdit', {
          user: user,
          officeId: Role.getOfficeId(user),
        })
      );
      // ユーザー削除ボタンの有効状態をセットする
      this.$set(
        this,
        'isUserDeleteButtonEnabled',
        Role.isButtonEnabled(this.userDetail(), 'userDelete', {
          user: user,
          officeId: Role.getOfficeId(user),
        })
      );
      // パスワード初期化ボタンの有効状態をセットする
      this.$set(
        this,
        'isUserPasswordResetButtonEnabled',
        Role.isButtonEnabled(this.userDetail(), 'userPasswordReset', {
          user: user,
          officeId: Role.getOfficeId(user),
        })
      );

      // ユーザーデータをセットする
      this.$set(this, 'user', user);

      // ユーザー情報表示の更新
      this.$set(this, 'userRecords', this.mapUser(user));
    },

    // ユーザーデータのマッピング
    mapUser(user) {
      return [
        { key: 'userName', value: user.userName },
        { key: 'loginId', value: user.loginId },
        { key: 'userMail', value: user.userMail },
        { key: 'salesCode', value: this.mapSalesInfo(user, 'salesCode') },
        {
          key: 'salesDepartmentName',
          value: this.mapSalesInfo(user, 'salesDepartmentName'),
        },
        {
          key: 'salesSectionName',
          value: this.mapSalesInfo(user, 'salesSectionName'),
        },
        {
          key: 'agencyCode',
          value:
            Role.isAgency(user.role) && user.agency
              ? user.agency.agencyCode
              : null,
        },
        {
          key: 'agencyName',
          value:
            Role.isAgency(user.role) && user.agency
              ? user.agency.agencyName
              : null,
        },
        {
          key: 'chargePersonName',
          value: Role.isAgency(user.role) ? user.chargePersonName : null,
        },
        {
          key: 'recruiterId',
          value: user.recruiterId,
        },
        {
          key: 'chargePersonPhoneNumber',
          value: Role.isAgency(user.role) ? user.chargePersonPhoneNumber : null,
        },
        { key: 'role', value: Role.getName(user.role) },
        {
          key: 'lastUpdatedDate',
          value: convertLastUpdatedDate(user.lastUpdatedDate),
        },
        { key: 'comment', value: splitMessageLine(user.comment) },
        {
          key: 'isDeleted',
          value: convertIsDeleted(user.isDeleted),
        },
        {
          key: 'lastLoginDate',
          value: convertLastLoginDate(user.lastLoginDate),
        },
        {
          key: 'firstRegisteredDate',
          value: convertFirstRegisteredDate(user.firstRegisteredDate),
        },
      ].filter(row => Role.canShow(this.userDetail().role, row.key));
    },

    // 営業店データのマッピング
    mapSalesInfo(user, key) {
      return user.sale // 所属営業店が存在する場合
        ? user.sale[key] // 所属営業店データとする
        : user.agency && user.agency.sale // 所属代理店が存在するかつ、紐づく営業店が存在する場合
        ? user.agency.sale[key] // 所属代理店に紐づく営業店データとする
        : null;
    },

    // ユーザー編集キャンセル
    cancelEditUser() {
      this.$set(this, 'showEditUserDialog', false);
    },

    // 仮パスワード再発行のキャンセル
    cancelResendPassword() {
      this.$set(this, 'showPasswordResendConfirmDialog', false);
    },

    // パスワード初期化のキャンセル
    cancelResetPassword() {
      this.$set(this, 'showPasswordResetConfirmDialog', false);
    },

    // 仮パスワードの再発行
    async resendPassword() {
      // ユーザーの仮パスワード再発行
      const result = await putUserPasswordResend(this.user.id).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.userPasswordResendError'),
          this.$t('error.userPasswordResendError')
        );
      });

      // ユーザーの仮パスワード再発行に失敗した場合、以降の処理を中止する
      if (!result) return;

      this.$set(this, 'showPasswordResendConfirmDialog', false);

      this.$refs.completedDialog.open(
        this.$t('title.agency.passwordResendResult'),
        this.$t('success.passwordResend')
      );
    },

    // パスワードの初期化
    async resetPassword() {
      // ユーザーのパスワード初期化
      const result = await putUserPasswordReset(this.user.id).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.userPasswordResetError'),
          this.$t('error.userPasswordResetError')
        );
      });

      // ユーザーのパスワード初期化に失敗した場合、以降の処理を中止する
      if (!result) return;

      this.$set(this, 'showPasswordResetConfirmDialog', false);

      this.$refs.completedDialog.open(
        this.$t('title.agency.passwordResetResult'),
        this.$t('success.passwordReset')
      );
    },

    // ユーザー更新
    async updateUser(item) {
      const user = {
        userName: item.userName,
        ...(!(this.user.userMail == item.userMail) && {
          userMail: item.userMail,
        }),
        ...(Role.isAgency(item.role) && {
          agencyCodeForIndividual: item.agencyCodeForIndividual,
        }),
        ...(Role.isAgency(item.role) && {
          agencyNameForIndividual: item.agencyNameForIndividual,
        }),
        ...(Role.isAgency(item.role) && {
          chargePersonName: item.chargePersonName,
        }),
        ...(Role.isAgency(item.role) && {
          chargePersonPhoneNumber: item.chargePersonPhoneNumber,
        }),
        recruiterId: item.recruiterId,
        comment: item.comment,
        role: item.role,
        ...(!Role.isAgency(item.role) && {
          salesCode: item.salesCode,
        }),
        ...(Role.isAgency(item.role) && {
          agencyCode: item.agencyCode,
        }),
      };
      // ユーザーの更新
      const result = await putUser(this.user.id, user).catch(error => {
        let message;
        switch (error.code) {
          case ErrorCode.NotAgency:
            message = this.$t('error.notAgency', {
              agencyCode: item.agencyCode,
            });
            break;
          case ErrorCode.NotSales:
            message = this.$t('error.notSales', { salesCode: item.salesCode });
            break;
          default:
            message = this.$t('error.userUpdateBadRequest');
        }
        this.$refs.errorDialog.open(
          this.$t('title.agency.userUpdateError'),
          message
        );
      });

      // ユーザーが編集できなかった場合、以降の処理を中止する
      if (!result) return;

      await this.fetchUsers();

      // ストア情報の更新
      const loginUserInfo = await getLoginUser().catch(() => {});
      this.setUserDetail(loginUserInfo);

      this.$set(this, 'showEditUserDialog', false);
      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },

    // 権限の変更時
    async onChangeRole(item) {
      // ユーザー編集項目の設定
      const editUserTargets = JSON.parse(
        JSON.stringify(this.editUserDefaultTargets)
      )
        .filter(
          target =>
            !target.pattern || target.pattern === Role.getUserType(item.value)
        )
        .filter(target => Role.canEdit(this.userDetail().role, target.key))
        .map(target => {
          // リストの取得
          if (target.listName) target.list = this[target.listName];
          // 入力値の取得
          const oldTarget = this.editUserTargets.find(
            t => t.targetName === target.targetName
          );
          if (oldTarget) target.value = oldTarget.value;

          return target;
        });

      // 関数の取得
      this.editUserDefaultTargets.forEach(target => {
        if (target.function) {
          editUserTargets.find(
            t => t.targetName === target.targetName
          ).function = target.function;
        }
      });

      this.$set(this, 'editUserTargets', editUserTargets);
    },

    // ユーザー情報編集ボタン押下時
    async onClickEditUserButton() {
      const user = {
        userName: this.user.userName,
        userMail: this.user.userMail,
        agencyCodeForIndividual: this.user.agencyCodeForIndividual,
        agencyNameForIndividual: this.user.agencyNameForIndividual,
        chargePersonName: this.user.chargePersonName,
        recruiterId: this.user.recruiterId,
        chargePersonPhoneNumber: this.user.chargePersonPhoneNumber,
        comment: this.user.comment,
        role: this.user.role,
        officeId: Role.getOfficeId(this.user),
        salesCode: this.user.sale ? this.user.sale.salesCode : null,
        agencyCode: this.user.agency ? this.user.agency.agencyCode : null,
      };

      // ユーザー編集項目の設定
      const editUserTargets = JSON.parse(
        JSON.stringify(this.editUserDefaultTargets)
      )
        .filter(
          target =>
            !target.pattern || target.pattern === Role.getUserType(user.role)
        )
        .filter(target => Role.canEdit(this.userDetail().role, target.key))
        .map(target => {
          // リストの取得
          if (target.listName) target.list = this[target.listName];
          // 初期値の取得
          target.value = user[target.targetName];
          return target;
        });

      // 関数の取得
      this.editUserDefaultTargets.forEach(target => {
        if (target.function) {
          editUserTargets.find(
            t => t.targetName === target.targetName
          ).function = target.function;
        }
      });

      this.$set(this, 'editUserTargets', editUserTargets);
      this.$set(this, 'showEditUserDialog', true);
    },

    // ユーザー削除ボタン押下時
    async onClickUserDeleteButton() {
      this.$refs.userDeleteDialog.open();
    },

    // パスワード初期化ボタン押下時
    async onClickResetPasswordButton() {
      // 最終ログイン日時が存在しない場合
      if (!this.user.lastLoginDate) {
        // 仮パスワード再発行ダイアログを表示する
        this.$set(this, 'showPasswordResendConfirmDialog', true);
      } else {
        // パスワード初期化ダイアログを表示する
        this.$set(this, 'showPasswordResetConfirmDialog', true);
      }
    },

    // ユーザー削除成功時
    async onSuccessDeleteUser() {
      // ユーザーの削除
      const result = await deleteUser(this.user.id).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.userDeleteError'),
          this.$t('error.userDeleteBadRequest')
        );
      });

      // ユーザーが削除できなかった場合、削除ダイアログを初期化して以降の処理を中止する
      if (!result) return this.$refs.userDeleteDialog.init();

      this.$refs.userDeleteDialog.close();

      // ユーザー一覧画面に遷移する
      this.$router.push('/user_list');
    },

    // 前のユーザーデータボタン押下時
    async onClickPrevUserButton() {
      await this.goPage(true);
    },

    // 次のユーザーデータボタン押下時
    async onClickNextUserButton() {
      await this.goPage(false);
    },

    // 他のページへ遷移する
    async goPage(isPrev) {
      const user = await getUserId(this.$route.params['user_id'], {
        isPrev: isPrev,
      }).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.userReadError'),
          this.$t('error.userReadBadRequest')
        );
      });

      // ユーザーが取得できなかった場合、以降の処理を中止する
      if (!user) return;

      const path = `/user_detail/${user.id}`;
      window.location.href = path;
    },

    // ホーム画面に戻るボタン押下時
    onClickBackHomeButton() {
      // ホーム画面に遷移する
      this.$router.push('/Home');
    },

    // ロックアウト時のスタイル
    checkLockoutStyle() {
      return this.isLocked ? TextStyle.Alert : '';
    },
  },
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v_base_table_with_border {
  border: solid 1px;
}
.v_base_table_fill_width {
  width: 50%;
}
.base_td_category_text {
  width: 230px;
  background: #cccccc;
}
.v-data-table td {
  word-break: break-all;
}
.v-data-table {
  overflow: hidden;
}
</style>
